exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assignment-writing-service-html-tsx": () => import("./../../../src/pages/assignment-writing-service.html.tsx" /* webpackChunkName: "component---src-pages-assignment-writing-service-html-tsx" */),
  "component---src-pages-business-html-tsx": () => import("./../../../src/pages/business.html.tsx" /* webpackChunkName: "component---src-pages-business-html-tsx" */),
  "component---src-pages-chemistry-html-tsx": () => import("./../../../src/pages/chemistry.html.tsx" /* webpackChunkName: "component---src-pages-chemistry-html-tsx" */),
  "component---src-pages-computer-science-html-tsx": () => import("./../../../src/pages/computer-science.html.tsx" /* webpackChunkName: "component---src-pages-computer-science-html-tsx" */),
  "component---src-pages-engineering-html-tsx": () => import("./../../../src/pages/engineering.html.tsx" /* webpackChunkName: "component---src-pages-engineering-html-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-java-html-tsx": () => import("./../../../src/pages/java.html.tsx" /* webpackChunkName: "component---src-pages-java-html-tsx" */),
  "component---src-pages-marketing-html-tsx": () => import("./../../../src/pages/marketing.html.tsx" /* webpackChunkName: "component---src-pages-marketing-html-tsx" */),
  "component---src-pages-mathematics-html-tsx": () => import("./../../../src/pages/mathematics.html.tsx" /* webpackChunkName: "component---src-pages-mathematics-html-tsx" */),
  "component---src-pages-nursing-html-tsx": () => import("./../../../src/pages/nursing.html.tsx" /* webpackChunkName: "component---src-pages-nursing-html-tsx" */),
  "component---src-pages-order-html-tsx": () => import("./../../../src/pages/order.html.tsx" /* webpackChunkName: "component---src-pages-order-html-tsx" */),
  "component---src-pages-physics-html-tsx": () => import("./../../../src/pages/physics.html.tsx" /* webpackChunkName: "component---src-pages-physics-html-tsx" */),
  "component---src-pages-programming-html-tsx": () => import("./../../../src/pages/programming.html.tsx" /* webpackChunkName: "component---src-pages-programming-html-tsx" */)
}

